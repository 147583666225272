$purple: #220C4A;
$white: #FFF;
$border-radius: 4px;
$gray-4: #e0e0e0;
$gray-5: #b8b8b8;

.container {
  display: inline-block;
  position: relative;
}

.tooltipContainer {
  background-color: $purple;
  color: $white;
  border-radius: $border-radius;
  text-align: center;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  transition: opacity 0.3s;
  z-index: 102;
  font-size: 10px;
  font-family: ClanPro-WideNews, sans-serif;
  width: max-content;
}

.tooltipArrow {
  height: 1rem;
  position: absolute;
  width: 1rem;

  &:before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }
  &:after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }

  &[data-placement*='bottom'] {
    height: 1rem;
    left: -6px !important;
    margin-top: -0.4rem;
    top: 1px;
    width: 1rem;
  }
  &[data-placement*='bottom']:before {
    border-color: transparent transparent $purple transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
    position: absolute;
    top: -1px;
  }
  &[data-placement*='bottom']:after {
    border-color: transparent transparent $purple transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
  }

  &[data-placement*='top'] {
    bottom: 0;
    height: 1rem;
    left: 0;
    margin-bottom: -0.96rem;
    width: 1rem;
  }
  &[data-placement*='top']:before {
    border-color: $purple transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
    position: absolute;
    top: 1px;
  }
  &[data-placement*='top']:after {
    border-color: $purple transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
  }

  &[data-placement*='right'] {
    height: 1rem;
    left: 0;
    margin-left: -0.7rem;
    width: 1rem;
  }
  &[data-placement*='right']:before {
    border-color: transparent $purple transparent transparent;
    border-width: 0.5rem 0.7rem 0.5rem 0;
  }
  &[data-placement*='right']:after {
    border-color: transparent $purple transparent transparent;
    border-width: 0.5rem 0.7rem 0.5rem 0;
    left: 0;
    top: 0;
  }

  &[data-placement*='left'] {
    height: 1rem;
    margin-right: -0.7rem;
    right: 0;
    width: 1rem;
  }

  &[data-placement*='left']:before {
    border-color: transparent transparent transparent $purple;
    border-width: 0.5rem 0 0.5rem 0.4em;
  }

  &[data-placement*='left']:after {
    border-color: transparent transparent transparent $purple;
    border-width: 0.5rem 0 0.5rem 0.4em;
    left: 3px;
    top: 0;
  }
}
