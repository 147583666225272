@import '../scss/variables';

@mixin scrollbar-min {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: lighten($gray-4, 5%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($gray-5, 10%);
    box-shadow: none;
    border-radius: 4px;
  }
}

.container {
  display: block;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 2px 4px rgba(34, 12, 74, 0.08);

  > div:last-of-type {
    margin-top: 2px;
  }
}

.label {
  display: flex;
  cursor: pointer;
  padding: 4px 14px 4px 8px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-family: 'ClanPro-News', sans-serif;
  color: $purple;
  position: relative;

  &:focus {
    background-color: $gray-4;
  }

  > div:last-of-type {
    min-width: max-content;
    width: 100%;
    > svg {
      fill: #220c4a;
      margin-left: 10px;
      position: absolute;
      right: 10px;
    }
  }

  &:hover,
  &.active {
    background-color: $gray-4;
  }
}

.dropdownContainer {
  position: absolute;
  display: none;
  height: fit-content;
  background-color: #fff;
  list-style-type: none;
  font-family: 'ClanPro-News', sans-serif;
  font-size: 14px;
  color: $purple;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
    0px 8px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
  border-radius: 4px;
  padding: 0;
  z-index: 999;
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  width: auto;
  text-align: left;
  margin: -2px 0;

  @include scrollbar-min;

  > li {
    padding: 8px 16px;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'ClanPro-News', sans-serif;
    font-size: 14px;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: #8000ff !important;
      color: #fff;
    }

    &.selected {
      background-color: #efefef;
    }
  }

  &.visible {
    display: flex;
  }
}

.dropdownTop {
  position: relative;

  .dropdownContainer {
    position: absolute;
    bottom: 0;
  }
}

.clearButton {
  margin-right: 10px;

  &:hover {
    svg {
      > path {
        fill: darken($gray, 20%);
      }
    }
  }
}

.disabled {
  pointer-events: none !important;
  > div {
    background-color: $gray-4;

    > div {
      opacity: 0.2;
    }
  }
}
