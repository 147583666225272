@font-face {
  font-family: 'ClanPro-Book';
  src: url('../fonts/FF Clan Pro Wide Book.otf') format('opentype');
}

@font-face {
  font-family: 'ClanPro-News';
  src: url('../fonts/FF Clan Pro Wide News.otf') format('opentype');
}

@font-face {
  font-family: 'ClanPro-Bold';
  src: url('../fonts/FF Clan Pro Wide Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'ClanPro-Wild-Bold-V2';
  src: url('../fonts/FF Clan Pro Wide Bold V2.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'ClanPro-Wide-News-V2';
  src: url('../fonts/FF Clan Pro Wide News V2.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'ClanPro-Wide-Regular';
  src: url('../fonts/FF Clan Pro Wide Regular.otf') format('opentype');
  font-weight: bold;
}
