@keyframes rotatePhone {
  0% {
    transform: rotate(100deg);
    transform-origin: 50% 50%;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: rotate(-10deg);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}