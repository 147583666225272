@font-face {
  font-family: 'ClanPro-Wide';
  src: url('/assets/fonts/ff-clan-pro-wide-book.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'ClanPro-Wide';
  src: url('/assets/fonts/ff-clan-pro-wide-news.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'ClanPro-Wide';
  src: url('/assets/fonts/ff-clan-pro-wide-bold.otf');
  font-weight: 800;
}