$black: #000000;
$black-opaque: #0000000d;
$black-opaque-2: #0000004d;
$black-opaque-3: #0000001f;
$blue: #13d2fc;
$border-radius: 5px;
$dark-gray: #888888;
$gray: #7d858c;
$gray-2: #cccccc;
$gray-3: #666666;
$gray-4: #e0e0e0;
$gray-5: #b8b8b8;
$gray-light: #f4f7fc;
$green: #2ce27e;
$green-2: #1ac165;
$green-light: #e5fff1;
$light: #ededed;
$light-gray: #f8f8f8;
$light-gray-1: #f5f5f5;
$light-gray2: #e2e5eb;
$light-gray-3: #aaaaaa;
$light-gray-4: #cccccc;
$light-gray-5: #e0e0e0;
$light-purple: #e2e6ed;
$light-blue: #c4f4fe;
$lighter-blue: #e4faff;
$purple: #220c4a;
$purple-2: #8000ff;
$violet: #8000ff;
$red: #ff5351;
$red-2: #ff211e;
$red-light: #ffeae9;
$super-light: #f4f7fc;
$white: #ffffff;
$yellow: #ffd65a;
$yellow-2: #FFF5D6;
$orange: #FF6C2B;
$light-orange: #ffd2be;
$sepia-3: #c99765;
$right-drawer-width: 600px;
$right-drawer-shaddow: -3px -3px 21px -4px rgba(0, 0, 0, 0.54);

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $gray-4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-3;
    box-shadow: none;
    border-radius: 8px;
  }
}

@mixin scrollbar-min {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: lighten($gray-4, 5%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($gray-5, 10%);
    box-shadow: none;
    border-radius: 4px;
  }
}

@mixin box-shadow-elevation-1 {
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 2px 4px rgba(34, 12, 74, 0.08);
}
