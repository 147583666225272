@import '../scss/variables';

.callout {
  display: flex;
  align-items: flex-start;
  min-width: 322px;
  border-radius: 4px;
  padding: 8px 16px 8px 0;
  font-size: 14px !important;
  line-height: 26px;
  color: $purple;

  > div:nth-of-type(1) {
    display: flex;
    flex: 0 0 56px;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: 4px;
  }

  &.primary:not(:disabled) {
    background-color: $blue;

    > div:nth-of-type(1) {
      > svg > path {
        fill: darken($blue, 10%);
      }
    }
  }

  &.success:not(:disabled) {
    background-color: $green;

    > div:nth-of-type(1) {
      > svg > path {
        fill: darken($green, 10%);
      }
    }
  }

  &.danger:not(:disabled) {
    background-color: $red;

    > div:nth-of-type(1) {
      > svg > path {
        fill: darken($red, 10%);
      }
    }
  }

  &.warning:not(:disabled) {
    background-color: $yellow-2;

    > div:nth-of-type(1) {
      > svg > path {
        fill: $orange;
      }
    }
  }

  &.default:not(:disabled) {
    background-color: $gray-4;

    > div:nth-of-type(1) {
      > svg > path {
        fill: darken($gray-4, 5%);
      }
    }
  }

  &.hidden {
    display: none !important;
  }

  &:disabled {
    background-color: $gray-4;
    color: darken($gray-4, 10%);
  }
}

.title {
  font-family: ClanPro-Bold, sans-serif;
  text-transform: capitalize;
  margin: 4px 0;
}
